import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';
import ScrollspyNav from 'react-scrollspy-nav';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import '../../../dynamic-pages/hsr-character-dynamic.scss';
import './hsr-guides.scss';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { HSRSkill } from '../../../modules/hsr/skills/hsr-skill';
import { HSRStat } from '../../../modules/hsr/common/components/hsr-stat';
import { HSRRelicSet } from '../../../modules/hsr/common/components/hsr-relic-set';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import { ProfileFeixiaoDPS } from '../../../modules/hsr/profile/feixiao-calcs';
import {
  faBriefcaseMedical,
  faFlask,
  faHandFist,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '../../../modules/common/components/box';

const SRGuidesFeixiao: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Feixiao - First Impressions</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_feixiao.jpg"
            alt="Feixiao - First impressions"
          />
        </div>
        <div className="page-details">
          <h1>Feixiao - First Impressions</h1>
          <h2>
            Check the early access first impressions of Feixiao - a new
            character coming soon to Honkai: Star Rail!
          </h2>
          <p>
            Last updated: <strong>04/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content character-hsr-v2 special-guide">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Disclaimer" />
              <Alert variant="primary alert-red">
                <p>
                  The information you can find in this article{' '}
                  <strong>
                    come from the Creator Experience server and as such are
                    subject to change
                  </strong>{' '}
                  - so while you can check out Feixiao's kit details a bit
                  early, please keep that in mind. A full guide for Feixiao will
                  be released as usual once the patch introducing her goes live.
                </p>
              </Alert>
              <p>
                Also, here's a <strong>special code</strong> to celebrate
                Feixiao's reveal!
              </p>
              <div className="codes">
                <Box isCentered>
                  <p className="code">XRPTL1P7K5H8W</p>
                  <p className="date">Released on 04.09.2023</p>
                </Box>
              </div>
              <p>
                You can also use this{' '}
                <a
                  href="https://hsr.hoyoverse.com/gift?code=XRPTL1P7K5H8W"
                  target="_blank"
                  rel="noreferrer"
                >
                  link here
                </a>{' '}
                to redeem the code in your web browser!
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Introduction" />
              <StaticImage
                src="../../../images/starrail/robin/intro.webp"
                alt="Feixiao"
              />
              <h6>Feixiao's Profile</h6>
              <p>
                <strong className={`Wind`}>Feixiao</strong> is a{' '}
                <strong className={`rarity-5`}>5★</strong> character from the{' '}
                <strong className={`Wind`}>Wind</strong> element who follows the{' '}
                <strong className={`Hunt`}>Path of The Hunt</strong>. The
                Xianzhou Yaoqing's Merlin's Claw and one of the Seven
                Arbiter-Generals. Her personality is unrestrained and frank,
                dashing and straightforward. She is skilled in all forms of
                martial arts and has honed herself into a supreme weapon. She is
                widely adored by Xianzhou soldiers and civilians alike as "The
                Great General." However, she bears the burden of the Moon Rage
                affliction. If she were to hunt down all the abominations in her
                limited lifetime — Then the only enemy Feixiao has would be
                herself.
              </p>
              <h6>Feixiao's Intro</h6>
              <p>
                The Merlin's Claw makes a bombastic first appearance, capturing
                the hearts of many a Trailblazer.{' '}
                <strong>
                  She is a CRIT based DPS character following the Path of The
                  Hunt, and is capable of a similar level of decimation as the
                  current top contenders of Firefly and Acheron
                </strong>
                . Much like Feixiao herself, her kit is very straightforward and
                doesn't mince words, resulting in a smooth, flexible, and most
                of all powerful character.
              </p>
              <p>
                <strong>Feixiao is a fearsome single-target DPS</strong> who
                deals constant and consistent follow-up damage and has numerous
                self-buffs that are effortless to maintain. She has a unique
                Energy mechanic not unlike Acheron, using a point based system
                instead of the typical Energy values, and her{' '}
                <strong>
                  Ultimate deals colossal total damage with two separate attack
                  types that can be done within said Ultimate, doing more damage
                  if the target is already Weakness Broken or if they have yet
                  to be Broken
                </strong>
                . Her upfront power is extraordinary and requires virtually no
                ramp-up whatsoever, giving her a lot of team flexibility on top.
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Traces and Eidolons" />
              <p>
                First, let's go over Feixiao's kit - so stats, skills, major
                traces and minor traces - and explain how they work.
              </p>
              <h6>Feixiao's Stats (level 80)</h6>
              <Row xs={1} xxl={4} className="va-section">
                <Col>
                  <div className="info-list-row">
                    <div className="category">HP</div>
                    <div className="details">1048</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">DEF</div>
                    <div className="details">388</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">ATK</div>
                    <div className="details">602</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Speed</div>
                    <div className="details">112</div>
                  </div>
                </Col>
              </Row>
              <h6>Feixiao's Skills</h6>
              <div className={`skills Wind`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="122001"
                        maxLevel={7}
                        element="Wind"
                        gen=""
                        break_primary="10"
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Well, nothing much can be said about Feixiao's basic attack -
                like majority of the characters, it's a simple one-target attack
                with average damage and you will rarely use it.
              </p>
              <div className={`skills Wind`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="122002"
                        maxLevel={12}
                        element="Wind"
                        gen="0"
                        break_primary="20"
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                <strong>
                  Feixiao's Skill deals up to 200% ATK to a single enemy, and
                  then immediately launches a free follow-up from her Talent.
                </strong>{' '}
                This is free because it doesn't count towards the 1-per-turn
                limit from ally attacks. While seemingly innocuous on the
                surface, this ends up dealing up to 310% ATK in a single turn,
                but spread across 2 actions, meaning every Skill usage that
                Feixiao has is guaranteed to give you a full point of Flying
                Aureus all on its own.{' '}
                <strong>
                  This similarly has no extra conditions attached to it, you
                  press the button and she does the thing
                </strong>{' '}
                (I'm looking at you, Dr. Ratio).
              </p>
              <div className={`skills Wind`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="122003"
                        maxLevel={12}
                        element="Wind"
                        gen=""
                        break_primary="90"
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Feixiao's ultimate will target one foe to duel in the sky,
                gaining access to two new abilities;{' '}
                <strong>
                  the Ult Q is Boltsunder Blitz and is comprised of fast unarmed
                  strikes, while the Ult E is Waraxe Skyward and has giant
                  swings of her waraxe.
                </strong>{' '}
                Both of these do the same base damage, up to 60% ATK, and she
                can use them a total of 6 times before automatically unleashing
                her inner One Punch Man to end the Ultimate with a hit dealing
                up to 120% ATK. The differences in choosing between Boltsunder
                Blitz and Waraxe Skyward depends on if the enemy is Weakness
                Broken or not; if they have yet to be Broken, Waraxe Skyward
                gets an additional 30% to its multiplier; if they have been
                Broken, Boltsunder Blitz gets an additional 30% instead.
              </p>
              <p>
                <strong>
                  This means if you are using the correct attack inside of the
                  Ultimate, the total consolidated damage of Feixiao's Ultimate
                  is up to 700% ATK against a single enemy.
                </strong>{' '}
                For the duration of the Ultimate, she also gets to deal
                Toughness damage ignoring Weakness Types, and even gets a free
                100% Weakness Break Efficiency while the enemy is not yet
                Broken. Yet again, there is no additional setup required for
                this to be at full strength, you will be at max power by virtue
                of just doing what you were going to be doing anyway.
              </p>
              <p>
                This is a breath of fresh air for players who have gotten
                fatigued at the micro-management required from certain other DPS
                characters, or from the pressure of needing greater vertical
                investment just to make a team work at all, let alone optimally.{' '}
                <strong>
                  You tell Feixiao to do something, and she will do just that.
                </strong>{' '}
                The benefit of being able to hold her Ultimate and bank up to 12
                points of Flying Aureus means you can wait until the best moment
                to use it and not miss out on damage; 6 points of allowed
                overcap is much more generous than the 3 points that Acheron
                requires, and can even allow Feixiao to perform 2 back-to-back
                Ultimates if you so choose.
              </p>
              <div className={`skills Wind`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="122004"
                        maxLevel={12}
                        element="Wind"
                        gen=""
                        break_primary=""
                        break_secondary=""
                        ult_cost={240}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Feixiao collects points of Flying Aureus, gaining 1 point for
                every 2 ally actions. She can hold up to 12 points in total but
                only needs 6 to activate and use her Ultimate.{' '}
                <strong>
                  Note that it specifically says actions, meaning it will
                  consider things like follow-ups, Extra Actions, ally
                  Ultimates, even summon damage like from Numby when giving
                  points of Flying Aureus.
                </strong>{' '}
                It will even count Feixiao's own follow-ups towards the
                acquisition of her next point. Only her Ultimate cannot gain
                Flying Aureus.
              </p>
              <p>
                The other part of her Talent is indeed Feixiao's aforementioned
                follow-up mechanic. Whenever one of Feixiao's teammates land an
                attack on an enemy, Feixiao will instantly launch a follow-up
                attack on said enemy, dealing up to 110% ATK. If the target died
                before Feixiao could attack, the follow-up will come out against
                a random different enemy. Whenever Feixiao uses this follow-up
                attack, she will also gain up to a 60% DMG increase for 2 turns.
                Feixiao can use this follow-up 1 time between turns.
              </p>
              <div className={`skills Wind`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="122007"
                        maxLevel={1}
                        element="Wind"
                        gen=""
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <h6>Feixiao's Major Traces</h6>
              <div className={`skills traces Wind`}>
                <Row xs={1} xl={2} xxl={3}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Wind`}>
                          A2
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Heavenpath</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Wind`}>
                        When the battle starts, gains <b>3</b> point(s) of
                        "Flying Aureus." At the start of a turn, if no follow-up
                        attack was launched via Talent in the previous turn,
                        then this counts as 1 toward the number of attacks
                        required to gain "Flying Aureus."
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Wind`}>
                          A4
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Formshift</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Wind`}>
                        When using Ultimate to deal DMG to an enemy target, it
                        is considered as a follow-up attack. Follow-up attacks'
                        CRIT DMG increases by <b>36%</b>.
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Wind`}>
                          A6
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Boltcatch</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Wind`}>
                        When using Skill, increases ATK by <b>48%</b>, lasting
                        for <b>3</b> turn(s).
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Her Bonus Abilities are extremely useful and add to the carnage
                she can deal.
              </p>
              <ul>
                <li>
                  A2 will kick-start Feixiao's Ultimate by granting her 3 points
                  of Flying Aureus at the start of a fight. In addition to this,
                  it will grant you half a point if Feixiao's Talent follow-up
                  somehow wasn't triggered before her next turn — this just
                  prevents you from missing out on the build-up to a point of
                  Flying Aureus in the case that Feixiao is unable to act, for
                  example. A nice quality of life thing to have.
                </li>
                <li>
                  Feixiao's A4 and A6 seriously juice her performance. The A6 is
                  a simple but effective 48% ATK increase for 3 turns just for
                  using her Skill, but her A4 makes her Ultimate in its entirety
                  count as a follow-up attack, making it eligible for all
                  follow-up exclusive buffs and enemy debuffs. Oh, and it also
                  makes all her follow-ups have an extra 36% CRIT DMG. So in the
                  span of a single action, Feixiao can grant herself 48% ATK,
                  60% DMG, and will also permanently have a bonus 36% CRIT DMG
                  passively for follow-ups. For free.
                </li>
              </ul>
              <h6>Feixiao's Minor Traces</h6>
              <div className="smaller-traces">
                <Row xs={1} xxl={3}>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="ATK%" />{' '}
                      <span className="value">+28%</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="CRIT Rate" />{' '}
                      <span className="value">+12%</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="DEF" />{' '}
                      <span className="value">+12.5%</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Eidolons" />
              <p>
                Now let's check Feixiao's Eidolons and how they affect her
                buffing capabilities and damage output.
              </p>
              <h6>Feixiao's Eidolons</h6>
              <div className="skills eidolons">
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Wind`}>E1</div>
                        <div className="skill-info">
                          <p className="skill-name">Skyward I Quell</p>
                          <p className="skill-type">Eidolon 1</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Wind`}>
                        <p>
                          After launching "Boltsunder Blitz" or "Waraxe
                          Skyward," additionally increases the Ultimate DMG
                          dealt by Feixiao by an amount equal to <b>10%</b> of
                          the original DMG, stacking up to <b>5</b> time(s) and
                          lasting until the end of the Ultimate action.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Wind`}>E2</div>
                        <div className="skill-info">
                          <p className="skill-name">Moonward I Wish</p>
                          <p className="skill-type">Eidolon 2</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Wind`}>
                        <p>
                          In the Talent's effect, for every <b>1</b> instance of
                          follow-up attack launched by ally targets, Feixiao
                          gains <b>1</b> point of "Flying Aureus." This effect
                          can trigger up to <b>6</b> time(s) per turn.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Wind`}>E4</div>
                        <div className="skill-info">
                          <p className="skill-name">Stormward I Hear</p>
                          <p className="skill-type">Eidolon 4</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Wind`}>
                        <p>
                          The follow-up attack from Talent has its Toughness
                          Reduction increased by <b>100%</b>, and when it
                          launches, increases this unit's SPD by 8%, lasting for{' '}
                          <b>2</b>
                          turn(s).
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Wind`}>E6</div>
                        <div className="skill-info">
                          <p className="skill-name">Homeward I Near</p>
                          <p className="skill-type">Eidolon 6</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Wind`}>
                        <p>
                          Increases the All-Type RES PEN of Ultimate DMG dealt
                          by Feixiao by <b>20%</b>. Talent's follow-up attack
                          DMG is considered as Ultimate DMG at the same time,
                          and its DMG multiplier increases by <b>140%</b>.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <h6>Eidolons reviewed</h6>
              <ul>
                <li>
                  <strong>E1</strong> will increase Feixiao's Ultimate DMG by up
                  to 10% per Boltsunder or Waraxe up to 50% until the end of the
                  Ultimate. This bonus is actually a separate unique multiplier
                  like Acheron has for her Nihility teammate passive, making
                  this actually become an absurd boost to have for no extra
                  effort, and even more ridiculous that it's on an E1 of all
                  things. It's not necessary... but it is damn strong.
                </li>
                <li>
                  <strong>E2</strong> will make it so that every time an ally
                  does a follow-up attack, Feixiao gains a full point of Flying
                  Aureus, triggering up to 6 times per turn. This puts her
                  generation into actual overdrive, giving Feixiao so many more
                  uses of her Ultimate that it's laughable. This is absolutely
                  the whale-bait Eidolon, but I'll be damned if it isn't
                  heinously tantalising.
                </li>
                <li>
                  <strong>E4</strong> doubles the Toughness damage of the Talent
                  follow-up and lets it additionally give an 8% SPD boost for 2
                  turns. Nothing fancy, but it makes her stronger.
                </li>
                <li>
                  <strong>E6</strong> will give Feixiao's Ultimate DMG 20%
                  All-Type RES PEN, make her Talent follow-up be additionally
                  considered as Ultimate DMG, and will increase the multiplier
                  of her Talent follow-up by 140%, for a new total of 261%.
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Relics and Light Cones" />
              <div className={`section-build Wind `}>
                <div className="build-stats">
                  <h6 className="with-margin">Recommended Main and Substats</h6>
                  <Row xs={1} xl={2} xxl={4} className="main-stats">
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Body</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="CRIT Rate" />
                          <span className="order">&gt;=</span>
                          <HSRStat stat="CRIT DMG" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Feet</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="ATK" />
                          <span className="order">=</span>
                          <HSRStat stat="Speed" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Planar Sphere</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="Wind DMG" />
                          <span className="order">&gt;</span>
                          <HSRStat stat="ATK" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Link Rope</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="ATK" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="box sub-stats">
                        <span>Substats:</span>
                        <p>
                          SPD (Until Desired Breakpoint) = CRIT RATE = CRIT DMG
                          &gt; ATK%
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <br />
                <h6>Recommended Endgame Stats (while buffed in combat)</h6>
                <ul>
                  <li>
                    HP: <strong>2800+</strong>
                  </li>
                  <li>
                    DEF: <strong>900+</strong>
                  </li>
                  <li>
                    ATK: <strong>2700 - 3300+</strong> (depending on whether
                    you're using SPD or ATK% Boots)
                  </li>
                  <li>
                    CRIT Rate: <strong>85-95%</strong>
                  </li>
                  <li>
                    CRIT DMG: <strong>134-154%</strong>
                  </li>
                  <li>SPD:</li>
                  <ul>
                    <li>
                      <strong>112+</strong> (ATK-focused build relying on
                      teammates for Aureus),{' '}
                    </li>
                    <li>
                      <strong>134+</strong> (To hit MoC Cycle breakpoint without
                      buffs, but with some Speed buffs, you can build less)
                    </li>
                  </ul>
                </ul>
                <hr />
                <p>More info about the stats:</p>
                <ul>
                  <li>
                    <strong>CRIT Rate & CRIT DMG</strong>:
                  </li>
                  <ul>
                    <li>
                      CRIT RATE is often a far more desirable stat than either
                      ATK% or CRIT DMG% on Feixiao's relics and Cones due to her
                      bonus ability which grants 36% CRIT DMG and her ease of
                      access to CRIT DMG buffs in her top team compositions.
                      Because of this it's entirely normal and optimal to expect
                      your Feixiao to have close to 90 - 100% CRIT while having
                      a relatively low CRIT DMG stat before entering combat.
                    </li>
                  </ul>
                  <li>
                    <strong>Speed</strong>:
                  </li>
                  <ul>
                    <li>
                      Speed is a specialized stat for Feixiao that isn't
                      mandatory but can be very strong in certain game modes,
                      team compositions and combat scenarios. Much of Feixiao's
                      damage stems from her Ultimate (70 - 75%+) which is
                      fuelled based on how many actions are performed by Feixiao
                      herself and her team. Feixiao generates a good amount of
                      charges for her Ultimate herself, but her team often
                      generates even more than that - especially when she's
                      paired with Follow-Up attack spammers like Moze or Topaz.
                      Because of this, Speed isn't as essential on her as on
                      other characters which opens her up to the possibility of
                      raw damage builds using choices such as ATK% boots to
                      achieve the biggest nukes possible while still retaining a
                      similar (albeit lower) number of ultimates in combat. ATK%
                      often achieves more raw damage in pure theoretical output
                      but SPD also has many advantages depending on the
                      situation at hand and can actually pull ahead if enough of
                      them align or suit your playstyle.
                    </li>
                    <li>
                      SPD has the advantage of allowing Feixiao to hit Speed
                      breakpoints in endgame game modes such as MoC granting
                      Feixiao whole entire additional actions sometimes multiple
                      times in a fight - this is a tremendous boon and can be
                      the difference between saving entire cycles or gaining an
                      Ultimate early enough to slay a boss or elite in time.
                      Having additional actions through speed also allows
                      Feixiao more opportunities to hit enemies outside of her
                      ultimate boosting her multi-target capabilities and
                      freeing up her ultimates for only the most important
                      targets. Finally Speed can also have far more benefit
                      depending on the teammates you're pairing Feixiao with,
                      some teammates require Feixiao to take action in order to
                      progress their own kits while others attack far less
                      meaning Feixiao will have to pick up the slack of
                      generating Aureus stacks herself.
                    </li>
                    <li>
                      Choosing between Speed and raw damage will depend on your
                      personal preferences and the content you're planning on
                      having Feixiao tackle.
                    </li>
                  </ul>
                </ul>
                <div className="build-relics">
                  <h6>Recommended Relic Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone Wind`}>
                      <div className={`percentage default`}>
                        <p>100%</p>
                      </div>
                      <HSRRelicSet
                        name="The Wind-Soaring Valorous"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        One of two best sets to use on Feixiao thanks to its
                        nice 12% ATK boost, 6% CRIT RATE bonus and most
                        importantly 36% Ultimate DMG% increase which for Feixiao
                        is always active thanks to her talent.
                      </p>
                    </div>
                    <div className={`single-cone Wind`}>
                      <div className={`percentage default`}>
                        <p>99.70%</p>
                      </div>
                      <HSRRelicSet
                        name="The Ashblazing Grand Duke"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        The second of Feixiao's two top relic set options thanks
                        to the large majority of her damage being considered
                        Follow-Up attacks. This makes the 20% Follow-Up Attack
                        DMG from the 2P incredibly strong while the 4P bonus is
                        also very easy to stack close to max stacks when it
                        counts thanks to the multi-hit nature of Feixiao's
                        ultimate.
                      </p>
                    </div>
                    <div className={`single-cone Wind`}>
                      <div className={`percentage default`}>
                        <p>94.97%</p>
                      </div>
                      <div className="double-set">
                        <HSRRelicSet
                          name="The Ashblazing Grand Duke"
                          compactMode
                          comment="2PC"
                        />
                        <HSRRelicSet
                          name="Pioneer Diver of Dead Waters"
                          compactMode
                          comment="2PC"
                        />
                      </div>
                    </div>
                    <div className="information">
                      <p>
                        An alternative option is to mix and match a combination
                        of 2P Grand Duke, 2P Pioneer or 2P Eagle of Twilight if
                        you have neither of the 4P sets above with satisfactory
                        sub-stats yet.
                      </p>
                    </div>
                  </div>
                  <h6 className="with-margin">Recommended Planetary Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone Wind`}>
                      <div className={`percentage default`}>
                        <p>100%</p>
                      </div>
                      <HSRRelicSet
                        name="Duran, Dynasty of Running Wolves"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Best planar option by far for Feixiao due to all of her
                        teams featuring an absurd amount of Follow-Up attack
                        triggers. This means the full bonus of this set is often
                        effective immediately offering a tremendous 25% CRIT DMG
                        and 25% Follow-Up attack damage.
                      </p>
                    </div>
                    <div className={`single-cone Wind`}>
                      <div className={`percentage default`}>
                        <p>94.60%</p>
                      </div>
                      <HSRRelicSet
                        name="Izumo Gensei and Takama Divine Realm"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        One of two alternatives if you haven't farmed a good sub
                        stat Duran set yet. Grants Feixiao 12% ATK
                        unconditionally and 12% of her best stat CRIT RATE if at
                        least one other hunt character is present in the team
                        with her. The stats on this planar are decent and
                        bringing another hunt character is business as usual for
                        Feixiao so this Planar is a decent option.
                      </p>
                    </div>
                    <div className={`single-cone Wind`}>
                      <div className={`percentage default`}>
                        <p>94.02%</p>
                      </div>
                      <HSRRelicSet
                        name="Inert Salsotto"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        The other alternative to Duran with close to equivalent
                        performance to Izumo but without the restriction. Grants
                        the more desirable stat of 15% Follow-Up attack damage
                        but only 8% CRIT RATE.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="build-cones">
                  <h6>Recommended Light Cones</h6>
                  <>
                    <div className="detailed-cones moc with-space">
                      <div className={`single-cone Wind`}>
                        <div className="percentage">
                          <p>122.51%</p>
                        </div>
                        <HSRLightCone
                          slug="i-venture-forth-to-hunt"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className={`single-cone Wind`}>
                        <div className="percentage">
                          <p>118.49%</p>
                        </div>
                        <HSRLightCone
                          slug="baptism-of-pure-thought"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className={`single-cone Wind`}>
                        <div className="percentage">
                          <p>107.71%</p>
                        </div>
                        <HSRLightCone
                          slug="worrisome-blissful"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className={`single-cone Wind`}>
                        <div className="percentage">
                          <p>100%</p>
                        </div>
                        <HSRLightCone
                          slug="cruising-in-the-stellar-sea"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className={`single-cone Wind`}>
                        <div className="percentage">
                          <p>98.84%</p>
                        </div>
                        <HSRLightCone
                          slug="swordplay"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className={`single-cone Wind`}>
                        <div className="percentage">
                          <p>96.56%</p>
                        </div>
                        <HSRLightCone
                          slug="in-the-night"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className={`single-cone Wind`}>
                        <div className="percentage">
                          <p>96.32%</p>
                        </div>
                        <HSRLightCone
                          slug="only-silence-remains"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Calculations" />
              <ProfileFeixiaoDPS />
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Synergy and Teams" />
              <h6>Feixiao's Synergy</h6>
              <p>
                Below you can find some characters that Feixiao has great
                synergy with.
              </p>
              <Table striped bordered responsive className="synergy-list">
                <thead>
                  <tr>
                    <th>Character</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="char">
                      <HSRCharacter mode="icon" slug="robin" enablePopover />
                    </td>
                    <td>
                      <p>
                        Robin is the premiere and best support for Feixiao
                        offering her an absurd combination of buffs, on hit
                        damage and action advancement that isn't currently
                        matched by any other character in the game. Robin's
                        buffs encompass a 50% DMG buff, a truck load of flat
                        attack, a total of 45% CRIT DMG for Follow-Up Attacks
                        and on hit Physical damage for the entire team all
                        alongside her signature full team boost. The buffs alone
                        are spectacular for Feixiao and the other Follow-Up
                        attackers she plays alongside but the full team
                        advancement does wonders for granting you more total
                        ultimates in a fight.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="char">
                      <HSRCharacter mode="icon" slug="topaz" enablePopover />
                    </td>
                    <td>
                      <p>
                        Topaz offers Feixiao some of the most Aureus stack
                        generation possible thanks to Numby and their constant
                        advancement. Enemies afflicted with Topaz's Proof of
                        Debt also take an absurd 50% additional damage from
                        Follow-Up attacks all on top of Topaz's respectable
                        personal damage. Bringing Topaz to Feixiao's teams will
                        grant her some of the most Ultimates and Damage
                        amplification of all non-support follow-up attackers.
                        Topaz also has the option to be played incredibly skill
                        point positive opening up more flexible team building
                        and gameplay options for Feixiao.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="char">
                      <HSRCharacter mode="icon" slug="moze" enablePopover />
                    </td>
                    <td>
                      <p>
                        Moze is able to deliver incredibly frequent Follow-Up
                        attacks which assists Feixiao greatly in fueling her
                        ultimate by generating Aureus stacks for her. Enemies
                        Moze marks as Prey are also afflicted with a debuff
                        which causes them to take 25% additional damage from
                        Follow-Up attacks, boosting much of Feixiao's damage
                        against them. Finally Moze also contributes some pretty
                        strong damage himself and also has some very appealing
                        Eidolons with his E2 specifically also significantly
                        buffing Feixiao's damage output.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="char">
                      <HSRCharacter
                        mode="icon"
                        slug="march-7th-swordmaster"
                        enablePopover
                      />
                    </td>
                    <td>
                      <p>
                        March 7th's Hunt path is a surprisingly potent teammate
                        for Feixiao with the two of them playing off each other
                        excellently. Setting Feixiao as the Shifu grants her a
                        solid Speed 10% buff and whopping 60% CRIT DMG after
                        March uses an Enhanced Basic ATK for 2 turns. Between
                        March's follow-up attacks, standard attacks and action
                        advances she also generates Feixiao a lot of Aureus
                        while also benefitting herself from Feixiao's own
                        incredibly frequent attacks as the Shifu. Similar to
                        Topaz March is also incredibly skill point positive
                        opening up more flexible team building and gameplay
                        options for Feixiao.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h6>Feixiao's Best Teams</h6>
              <div className={`character-teams box Wind`}>
                <div className="team-header">
                  <p>Best Feixiao Team (Premium)</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="feixiao"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="topaz" enablePopover />
                      </div>
                      <div className="role specialist">
                        <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="robin" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="aventurine"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Alternative Feixiao Teams</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="feixiao"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi-equal">
                        <HSRCharacter mode="icon" slug="moze" enablePopover />
                        <HSRCharacter
                          mode="icon"
                          slug="march-7th-swordmaster"
                          enablePopover
                        />
                      </div>
                      <div className="role specialist">
                        <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="robin" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi-equal">
                        <HSRCharacter
                          mode="icon"
                          slug="aventurine"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>No Robin Teams</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="feixiao"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi-equal">
                        <HSRCharacter mode="icon" slug="topaz" enablePopover />
                        <HSRCharacter
                          mode="icon"
                          slug="march-7th-swordmaster"
                          enablePopover
                        />
                      </div>
                      <div className="role specialist">
                        <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi-equal">
                        <HSRCharacter mode="icon" slug="bronya" enablePopover />
                        <HSRCharacter
                          mode="icon"
                          slug="sparkle"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi-equal">
                        <HSRCharacter
                          mode="icon"
                          slug="aventurine"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Double Trouble Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="feixiao"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi-equal small">
                        <HSRCharacter mode="icon" slug="topaz" enablePopover />
                        <HSRCharacter mode="icon" slug="moze" enablePopover />
                        <HSRCharacter
                          mode="icon"
                          slug="march-7th-swordmaster"
                          enablePopover
                        />
                      </div>
                      <div className="role specialist">
                        <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi-equal small">
                        <HSRCharacter mode="icon" slug="topaz" enablePopover />
                        <HSRCharacter mode="icon" slug="moze" enablePopover />
                        <HSRCharacter
                          mode="icon"
                          slug="march-7th-swordmaster"
                          enablePopover
                        />
                      </div>
                      <div className="role specialist">
                        <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi-equal">
                        <HSRCharacter
                          mode="icon"
                          slug="aventurine"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                In the <strong>Double Trouble</strong> team pick any pair of the
                three listed characters.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-7">
              <SectionHeader title="What's Next?" />
              <p>
                That's the end of the early impressions article for Feixiao and
                we hope you did enjoy it! As we mentioned in the introduction
                section,{' '}
                <strong>
                  full Feixiao guide will be released and placed on her profile
                  once she's properly available in the game
                </strong>{' '}
                and then she will be also added to our tier list.
              </p>
              <p>Till then!</p>
              <StaticImage
                src="../../../images/starrail/robin/outro.webp"
                alt="Feixiao"
              />
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Disclaimer</a>
                </li>
                <li>
                  <a href="#section-1">Introduction</a>
                </li>
                <li>
                  <a href="#section-2">Traces</a>
                </li>
                <li>
                  <a href="#section-3">Eidolons</a>
                </li>
                <li>
                  <a href="#section-4">Relics and Light Cones</a>
                </li>
                <li>
                  <a href="#section-5">Calculations</a>
                </li>
                <li>
                  <a href="#section-6">Synergy and Teams</a>
                </li>
                <li>
                  <a href="#section-7">What's Next?</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SRGuidesFeixiao;

export const Head: React.FC = () => (
  <Seo
    title="Feixiao - First Impressions | Honkai: Star Rail | Prydwen Institute"
    description="Check the early access first impressions of Feixiao - a new character coming soon to Honkai: Star Rail!"
  />
);
